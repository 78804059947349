<template>
  <div>
    <CDataTable
      :items="items"
      :fields="fields"
      table-filter
      :noItemsView="noItemsPage"
      :tableFilter="false"
      :clickableRows="clickableRows"
      hover
      sorter
      :pagination="pagination"
      :itemsPerPage="itemsPerPage"
      :loading="loading"
    >
      <template
        v-for="(x, slotName) in $scopedSlots"
        v-slot:[slotName]="context"
      >
        <slot :name="slotName" v-bind="context" />
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  name: "Tables",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    clickableRows: {
      type: Boolean,
      default: false,
    },
    itemsPerPageSelect: {
      type: Object,
      default() {
        return { label: "ページごとに項目:", values: [50, 100, 200] };
      },
    },
    noItemsPage: {
      type: Object,
      default() {
        return {
          noResults: "絞り込みの結果がありません。",
          noItems: "有りませんでした。",
        };
      },
    },
    filterTable: {
      type: Object,
      default() {
        return { label: "絞り込み:", placeholder: "文字を入力..." };
      },
    },
    columSort: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
