<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            スラグ管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="createSlag()" class="button-create-user">
            新規追加
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row pt-2">
        <div class="row-input col-6 cpx-4">
          <input
            class="form-control w-100"
            v-model="keywords"
            placeholder="検索"
            v-on:keyup="getListSlags(1)"
          />
        </div>
      </div>
      <div class="row my-4">
        <div class="col-6 m-0 row align-items-center">
          <label class="col-3 font-weight-bold pl-0 m-0">スラグ作成日時</label>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="dateCreateFrom"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListSlags(1)"
            ></date-picker>
          </div>
          <div class="col-1 text-center p-0">~</div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="dateCreateTo"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListSlags(1)"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-6 m-0 row align-items-center">
          <div class="col-3 p-0">
            <label class="font-weight-bold m-0">スラグ最終更新日時</label>
          </div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="dateUpdatedFrom"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListSlags(1)"
            ></date-picker>
          </div>
          <span class="col-1 text-center p-0">~</span>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="dateUpdatedTo"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListSlags(1)"
            ></date-picker>
          </div>
        </div>
      </div>
      <Tables :items="listSlag.data" :fields="fields" :itemsPerPageSelect="{}">
        <template v-slot:keywords-header>
          <div>スラグ名（英字）</div>
        </template>
        <template v-slot:created_at-header>
          <div>スラグ作成日時</div>
        </template>
        <template v-slot:updated_at-header>
          <div>スラグ 最終更新日時</div>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{ listSlag.total - index - (listSlag.current_page - 1) * limit }}
          </td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <CButton
              class="mx-1 text-nowrap btn-other"
              color="primary"
              square
              variant="outline"
              size="sm"
              v-on:click="detailSlag(item.id)"
            >
              編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap btn-del"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-slag
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSlag.last_page"
      >
        <p class="font-weight-bold">
          {{ listSlag.total }}件中{{ listSlag.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSlag.last_page"
      >
        <CPagination
          :activePage.sync="page"
          :pages="listSlag.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-slag" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            :disabled="isDisable"
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="blockSlag(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-slag')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { Constants } from "../../utils/constants";
import { Urls } from "../../utils/urls";
import { Api } from "../../utils/http-common";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "listSlag",
  data() {
    return {
      fields: tableFields.SLAG,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      keywords: "",
      email: "",
      dateCreateFrom: "",
      dateCreateTo: "",
      dateUpdatedFrom: "",
      dateUpdatedTo: "",
      limit: 50,
      page: 1,
      // setting_link: null,
      // settingLinkOptions: [
      //   { text: "なし", value: 0 },
      //   { text: "あり", value: 1 },
      // ],
      isDisable: false,
      isLoading: false,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  async created() {
    await this.getListSlags(1);
  },
  computed: {
    ...mapGetters(["listSlag", "message", "success", "error"]),
    // lengthList() {
    //   if (this.listSlag) {
    //     return this.listSlag.total;
    //   }
    //   return null;
    // },
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },
    listSlag() {
      this.page = this.listSlag.current_page;
      this.isLoading = false;
      this.lengthList = this.listSlag.total;
    },
    page() {
      this.getListSlags(this.page);
    },
  },
  methods: {
    ...mapActions({ getListSlag: "getListSlag" }),
    ...mapActions({ deleteSlag: "deleteSlag" }),
    getDate(date) {
      return moment(new Date(date)).format("YYYY/MM/DD");
    },
    getHour(date) {
      return moment(new Date(date)).format("hh:mm");
    },
    createSlag() {
      this.$router.push({
        name: this.$route.params.shopId ? "create slag" : "create slag domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async getListSlags(page) {
      if (page === undefined) {
        page = this.page;
      }
      this.isLoading = true;
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          created_from: this.dateCreateFrom,
          created_to: this.dateCreateTo,
          updated_from: this.dateUpdatedFrom,
          updated_to: this.dateUpdatedTo,
          // setting_link: this.setting_link,
          limit: this.limit,
        },
      };
      await this.getListSlag(formData);
    },
    blockSlag(id) {
      this.isDisable = true;

      Api.adminRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.SLAG}/${id}?shop_id=${this.shop_id}`)
        .then((response) => {
          const { data } = response;
          this.$bvModal.hide("delete-modal-slag");
          this.isDisable = false;
          if (data.success) {
            this.$store.commit("set", ["success", true]);
            this.$store.commit("set", ["message", data.message]);
            this.listSlag.data = this.listSlag.data.filter((item) => {
              return item.id != id;
            });
            this.listSlag.total -= 1;
            this.getListSlags(this.page);
          } else {
            this.$store.commit("set", ["message", data.message]);
            this.$store.commit("set", ["error", true]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isDisable = false;
          return false;
        });
    },
    editSlag(id) {
      this.$router.push({
        name: this.$route.params.shopId ? "edit slag" : "edit slag domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListSlags(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
    clearInputSearch() {
      this.keywords = "";
      this.dateCreateFrom = "";
      this.dateCreateTo = "";
      this.dateUpdatedFrom = "";
      this.dateUpdatedTo = "";
      this.getListSlags(1);
      // this.setting_link = "";
    },
    detailSlag(id) {
      if (this.$route.params.shopId) {
        const shop_id = this.$route.params.shopId;
        this.$router.push({
          name: "edit slag",
          params: {
            shopId: shop_id,
            id: id,
          },
        });
      } else {
        this.$router.push({
          name: "edit slag domain",
          params: {
            id: id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
}
</style>
